import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Footer from "./Footer"
// import Navigation from "./Navigationnew"
import Navigation from "./Navprastype"
import { Row, Col, Carousel, Modal } from "react-bootstrap"
import { FaDotCircle, FaFilter } from "react-icons/fa"
import Url from './Url';

const Allarticlelist = () => {

    const url1 = Url()
    const url = url1["url"]
    const s3 = url1["s3"]
    const [s3url, setS3url] = useState(s3)

    ///////////////Render top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [getarticle, setGetarticle] = useState([])

    const [filteredArticles, setFilteredArticles] = useState([]);
    const [priceRange, setPriceRange] = useState([0, 25000]);

    const [showfilter, setShowfilter] = useState(false)

    const [mobilescr, setMobilescr] = useState(false)
    const [getdevwidth, setGetdevwidth] = useState("")

    useEffect(() => {

        setGetdevwidth(window.innerWidth + 'px')

        if (window.innerWidth <= 500) {
            setMobilescr(true)
        }
        //console.log(getdevwidth)

    }, [getdevwidth])

    // useEffect(() => {
    //     setGetbanner("WELCOME TO AARADHY SILK HAND CRAFT")
    // }, [])

    useEffect(() => {
        const api = url + "getArticle"

        fetch(api, {
            method: 'GET',
        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                //console.log(actualdata)
                if (actualdata.message != "Fail") {
                    setGetarticle(actualdata)
                }

            })
    }, [])

    useEffect(() => {
        const filtered = getarticle.filter(
            article => article.article_price >= priceRange[0] && article.article_price <= priceRange[1]
        );
        setFilteredArticles(filtered);
    }, [priceRange, getarticle]);

    const handlePriceChange = (event, newValue) => {
        setPriceRange(newValue);
    };

    const handleCloseModal = () => {
        setShowfilter(false)

    };

    const addToRecentlyViewed = (article) => {
        const recentlyViewed = JSON.parse(localStorage.getItem('recentlyViewed')) || [];
        const updatedRecentlyViewed = [article, ...recentlyViewed.filter(item => item.id !== article.id)].slice(0, 8);
        localStorage.setItem('recentlyViewed', JSON.stringify(updatedRecentlyViewed));
    };

    const formatProductName = (name) => {
        const words = name.split(' ');
        if (words.length <= 6) return name.toUpperCase();
        return words.slice(0, 6).join(' ').toUpperCase() + '...';
    };

    return (

        < >

            <Row>
                <Navigation />
            </Row>

            {/* <Row className="appointcenterhead mt-3 mx-1">
                {!mobilescr ? <>
                    <Col sm={1} className="justify_content_left">
                        <Link to="/">Home {"/"}</Link>
                    </Col>
                    <Col xs className="justify_content_left">All Article</Col>
                </>
                    :
                    <>
                        <Col xs={2} className="justify_content_left">
                            <Link to="/">Dashboard {"/"}</Link>
                        </Col>
                        <Col xs className="justify_content_left">All Article</Col>
                    </>
                }
            </Row> */}
            {/* <div className="appointcenterhead mt-3 mx-3" style={{ display: "flex" }}>
                <span className="justify_content_left">
                    <Link to="/">
                        Home {"> "}</Link>
                </span>
                <span className="justify_content_left">{" "} All Article</span>
            </div> */}

            {!mobilescr ?
                <Row className="homecounterbg mx-3  mb-5">
                    <Col sm={3}>
                        <div className="filter-section mx-5">
                            <h3 className="filter-heading">Filter</h3>
                            <div className="price-filter">
                                <h4 className="price-heading">Price Range</h4>
                                <div className="price-labels">
                                    <span>₹{priceRange[0]}</span>
                                    <span>₹{priceRange[1]}</span>
                                </div>
                                <input
                                    type="range"
                                    min="0"
                                    max="25000"
                                    value={priceRange[1]}
                                    onChange={(e) => handlePriceChange(e, [priceRange[0], parseInt(e.target.value)])}
                                    className="price-slider"
                                />
                            </div>
                        </div>
                    </Col>
                    <Col sm>
                        <Row>
                            {filteredArticles.length > 0 ? filteredArticles.map(item => (
                                <Col sm={2} className="homecountercardalllistbhiya justify_content_center mt-5" key={item.id}>
                                    <Link to={"/Viewarticle/" + item.id + "/" + item.article_name} onClick={() => addToRecentlyViewed(item)}>
                                        <Col>
                                            <Row className="justify-content-center">
                                                <img src={s3url + item.article_Thumbnail} alt="" className="featuresimgbhiya" />
                                            </Row>
                                            <Row className="homecountercardtext justify-content-center mt-2">
                                                {item.article_name}
                                            </Row>
                                            <Row className="homecountercardtext justify-content-center mt-2">
                                                ₹{item.article_price}
                                            </Row>
                                        </Col>
                                    </Link>
                                </Col>
                            )) : <Col className="text-center mt-5">No articles found in this price range.</Col>}
                        </Row>
                    </Col>
                </Row>
                :
                <>
                    <Row className="mt-2">
                        <Col xs></Col>
                        <Col xs={2}>
                            {!showfilter?
                                <FaFilter onClick={() => setShowfilter(true)} />
                            : 
                            <FaFilter onClick={() => setShowfilter(false)} />
                            }
                        </Col>
                    </Row>

                    {showfilter ?
                        <div className="filter-section mx-5">
                            <h3 className="filter-heading">Filter</h3>
                            <div className="price-filter">
                                <h4 className="price-heading">Price Range</h4>
                                <div className="price-labels">
                                    <span>₹{priceRange[0]}</span>
                                    <span>₹{priceRange[1]}</span>
                                </div>
                                <input
                                    type="range"
                                    min="0"
                                    max="25000"
                                    value={priceRange[1]}
                                    onChange={(e) => handlePriceChange(e, [priceRange[0], parseInt(e.target.value)])}
                                    className="price-slider"
                                />
                            </div>
                        </div>
                        :
                        null
                    }

                    <Row className="homecounterbg justify_content_center mt-2 mb-5">
                        <Row className="justify_content_center">
                            {filteredArticles.length > 0 ? filteredArticles.map(item => (
                                <Col xs={5} className="homecountercardalllistbhiya justify_content_center mt-2" key={item.id}>
                                    <Link to={"/Viewarticle/" + item.id + "/" + item.article_name} onClick={() => addToRecentlyViewed(item)}>
                                        <Col>
                                            <Row className="justify-content-center">
                                                <img src={s3url + item.article_Thumbnail} alt="" className="featuresimgbhiya" />
                                            </Row>
                                            <Row className="homecountercardtextbhiya mt-2 mx-2">
                                                {formatProductName(item.article_name)}
                                            </Row>
                                            <Row className="homecountercardtext justify_content_center mt-2 mx-2">
                                                ₹{item.article_price}
                                            </Row>
                                        </Col>
                                    </Link>
                                </Col>
                            )) : <Col className="text-center mt-5">No articles found in this price range.</Col>}
                        </Row>

                        {/* <Carousel fade className="collectioncarouselbg">
                        {getarticle.length > 0 ? getarticle.map(item => (
                            <Carousel.Item interval='3000' className="justify-content-center">
                                <Link to={"/Viewarticle/" + item.article_Id} target="_blank">
                                    <Col >
                                        <Row className="justify-content-center">
                                            <img src={s3url + item.article_Thumbnail} alt="" className="featuresimg1" />
                                        </Row>
                                        <Row className="homecountercardtext justify-content-center mt-2">
                                            {item.article_name}
                                        </Row>
                                        <Row className="homecountercardtext justify-content-center mt-2 ">
                                            ₹{item.article_price}
                                        </Row>
                                        <Row className="homecountercardtexred justify-content-center mt-2 mb-4" >
                                            <FaDotCircle /> In stock, only one Unit
                                        </Row>
                                    </Col>
                                </Link>
                            </Carousel.Item>
                        ))
                            :
                            null
                        }
                    </Carousel> */}
                    </Row>
                </>
            }

            <Row className="footermargin  ">
                <Footer />
            </Row>

        </>
    )
}

export default Allarticlelist